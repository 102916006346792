function destroyClientButton(_context) {
  return {
    onclick: () => App.broadcast("showspinner")
  };
}

export default {
  name: "destroy-client-button",
  fn: destroyClientButton
};
