import Editor from "./editor";

export default class EventForm {
  constructor(id) {
    this.storeData = this.storeData.bind(this);
    this.removeTemplateButton = this.removeTemplateButton.bind(this);
    this.id = id;
    this.templateButtonVisible = true;
    this.eventsHtml = $("#events").html();
    this.initEditor();
    this.localStorage();
    this.cancelButton();
  }

  initEditor() {
    this.editor = new Editor(this.id);
    this.editor.onChange(this.storeData);
    this.editor.onChange(this.removeTemplateButton);
    return this.editor.focus();
  }

  localStorage() {
    var $localStorageArea, value;
    value = localStorage[`event_${this.id}`];
    $localStorageArea = $(`#local_storage_area_${this.id}`);
    $localStorageArea.toggle(value != null);
    $(".js-edit-template").toggle(value == null);
    return $(`a#local_storage_event_${this.id}`).on("click", () => {
      this.editor.setHTML(value);
      return $localStorageArea.hide();
    });
  }

  cancelButton() {
    return $(".js-cancel-event").click(function(e) {
      e.preventDefault();
      if (confirm("Zeker weten?")) {
        $("#new_event").empty();
        $("#new_event_buttons").show();
        return localStorage.clear();
      }
    });
  }

  storeData() {
    return (localStorage[`event_${this.id}`] = this.editor.getHTML());
  }

  removeTemplateButton() {
    if (!this.templateButtonVisible) {
      return;
    }
    $(".js-edit-template").hide();
    return (this.templateButtonVisible = false);
  }
}
