function photoZoom(context) {
  return {
    init: function() {
      var el;
      el = $(context.getElement());
      if (/missing\.png$/.test(el.attr("src"))) {
        return;
      }
      return el.tooltip({
        animation: true,
        html: true,
        placement: "right",
        title: function() {
          return `<img src='${el.attr("src")}' height='100' width='100'/>`;
        }
      });
    }
  };
}

export default {
  name: "photo-zoom",
  fn: photoZoom
};
