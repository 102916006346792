import Flatpickr from "stimulus-flatpickr";
import { Dutch } from "flatpickr/dist/l10n/nl";
import "flatpickr/dist/themes/light.css";

export default class extends Flatpickr {
  connect() {
    this.config = {
      locale: Dutch,
      altInput: true,
      altFormat: "j F Y",
      mode: this.element.dataset.flatpickrMode || "single"
    };

    super.connect()
  }
}
