function autoSaveField(context) {
  var time;
  time = void 0;
  return {
    init: function() {
      return (time = Date.now());
    },
    onchange: function() {
      if (Date.now() - time < 1000) {
        return;
      }
      return $(context.getElement()).submit();
    }
  };
}

export default {
  name: "auto-save-field",
  fn: autoSaveField
};
