import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    option: String
  }

  connect() {
    this.hide()
    document.addEventListener('select-with-commentable-options:select', this.toggle.bind(this))
  }

  toggle({detail: {option}}) {
    if(option == this.optionValue){
      this.show()
    } else {
      this.hide()
    }
  }

  hide(){
    this.element.classList.add('u-hidden')
  }

  show(){
    this.element.classList.remove('u-hidden')
  }
}
