import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    setTimeout(()=> {
      this.change()
    }, 0)
  }

  change() {
    this.dispatch("select", {detail: {option:  this.element.value}})
  }

}
