import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button", "input", "block", "resultPopup", "result"];

  initialize() {
    this.index = 0;
    this.archived = false;
  }

  toggle() {
    this.blockTarget.classList.toggle("is-active");
    this.inputTarget.focus();
    if (!this.blockTarget.classList.contains("is-active")) this.closeResults();
  }

  search(event) {
    const query = event.currentTarget.value;
    if (query.length > 2) {
      this.executeSearch(query);
    } else {
      this.closeResults();
    }
  }

  list(event) {
    if (/(ArrowUp|ArrowDown|Enter|Escape)/.test(event.code)) {
      switch (event.code) {
        case "ArrowUp":
          this.index = this.index === 0 ? 0 : this.index - 1;
          this.selectResult();
          break;
        case "ArrowDown":
          this.index =
            this.index === this.resultTargets.length - 1
              ? this.index
              : this.index + 1;
          this.selectResult();
          break;
        case "Escape":
          this.closeResults();
          break;
        case "Enter":
          if (this.resultTargets[this.index].children[1]) {
            Turbolinks.visit(this.resultTargets[this.index].children[1].href);
          } else {
            this.enableArchive();
          }
          break;
      }
      event.preventDefault();
      return false;
    }
  }

  openResults() {
    this.blockTarget.classList.add("is-open");
  }

  closeResults() {
    this.blockTarget.classList.remove("is-open");
  }

  executeSearch(query) {
    this.blockTarget.classList.add("is-busy");
    fetch(`/search?term=${query}&archived=${this.archived ? 1 : 0}`)
      .then(response => {
        return response.text();
      })
      .then(response => {
        this.index = 0;
        this.openResults();
        this.resultPopupTarget.innerHTML = response;
        this.blockTarget.classList.remove("is-busy");
        this.selectResult();
      });
  }

  selectResult() {
    this.resultTargets.forEach(result =>
      result.classList.remove("is-selected")
    );
    this.resultTargets[this.index].classList.add("is-selected");
  }

  enableArchive(event) {
    this.archived = true;
    this.executeSearch(this.inputTarget.value);
    event.preventDefault();
  }

  hoverResult(event) {
    this.index = this.resultTargets.findIndex(
      result => event.currentTarget == result
    );
    this.selectResult();
  }
}
