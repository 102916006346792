function newClientLogin(context) {
  var render;
  render = function() {
    var el, placeholder, textarea;
    el = $(context.getElement());
    textarea = el.find("#client_login_invitation_message");
    placeholder = el.find(
      ".email-preview__body *:contains('{{introductietekst}}')"
    );
    return placeholder.first().replaceWith(textarea);
  };
  return {
    init: function() {
      return render();
    }
  };
}

export default {
  name: "new-client-login",
  fn: newClientLogin
};
