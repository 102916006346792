function legalRepresentativeForm(context) {
  var choiceField,
    formFields,
    infoField,
    legalRepChoice,
    legalRepType,
    resetInputFields,
    resetValue,
    setChoiceField,
    setFormState,
    setHiddenFormFields,
    setInfoField;
  choiceField = $(context.getElement()).children(
    '[data-type="hidden-field-choice"]'
  );
  infoField = $(context.getElement()).children(
    '[data-type="hidden-field-info"]'
  );
  legalRepType = function() {
    return $("#registration_form_legal_representative_type option:checked");
  };
  legalRepChoice = function() {
    return $("#registration_form_legal_representative_choice option:checked");
  };
  formFields = $(context.getElement()).find("input");
  resetValue = function(field) {
    return (field.value = field.defaultValue);
  };
  setChoiceField = function(typeValue) {
    if (typeValue && typeValue !== "Geen") {
      return choiceField.show();
    } else {
      return choiceField.hide();
    }
  };
  setInfoField = function(choiceValue) {
    if (choiceValue === "De bovenstaande contactpersoon") {
      return infoField.hide();
    } else if (choiceValue === "Iemand anders, namelijk:") {
      return infoField.show();
    } else {
      return infoField.hide();
    }
  };
  setHiddenFormFields = function(typeValue, choiceValue) {
    var contactLegalRep = $("#contact-legal-representative");
    var legalRep = $("#legal-representative-role");
    if (typeValue === "Geen") {
      legalRep.val(null);
      return contactLegalRep.val(null);
    } else if (choiceValue === "De bovenstaande contactpersoon") {
      legalRep.val(null);
      return contactLegalRep.val(typeValue);
    } else if (choiceValue === "Iemand anders, namelijk:") {
      contactLegalRep.val(null);
      return legalRep.val(typeValue);
    }
  };
  resetInputFields = function(typeValue, choiceValue) {
    var field, i, j, len, len1, results, results1;
    if (typeValue === "Geen") {
      results = [];
      for (i = 0, len = formFields.length; i < len; i++) {
        field = formFields[i];
        results.push(resetValue(field));
      }
      return results;
    } else if (choiceValue === "De bovenstaande contactpersoon") {
      results1 = [];
      for (j = 0, len1 = formFields.length; j < len1; j++) {
        field = formFields[j];
        results1.push(resetValue(field));
      }
      return results1;
    }
  };
  setFormState = function(typeValue, choiceValue) {
    setChoiceField(typeValue);
    setInfoField(choiceValue);
    setHiddenFormFields(typeValue, choiceValue);
    return resetInputFields(typeValue, choiceValue);
  };
  return {
    init: function() {
      return setFormState(legalRepType().val(), legalRepChoice().val());
    },
    onchange: function(_event, _element, _elementType) {
      return setFormState(legalRepType().val(), legalRepChoice().val());
    }
  };
}

export default {
  name: "legal-representative-form",
  fn: legalRepresentativeForm
};
