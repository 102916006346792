import * as d3 from "d3";

const MonthStats = class MonthStats {
  constructor(options, maxCapacity1) {
    this.maxCapacity = maxCapacity1;
    this.month = options.month;
    this.candidateCount = options.candidate_count;
    this.placedCount = options.placed_count;
  }

  cumulativeWaitingList() {
    return this.cumulativePlacedCandidates() + this.waitinglist();
  }

  cumulativePlacedCandidates() {
    return this.placedCount + this.placedCandidates();
  }

  waitinglist() {
    return Math.max(0, this.candidateCount - this._available());
  }

  placedCandidates() {
    return Math.min(this.candidateCount, this._available());
  }

  availableWithCandidates() {
    return Math.max(
      0,
      this.maxCapacity - this.placedCount - this.placedCandidates()
    );
  }

  _available() {
    return Math.max(0, this.maxCapacity - this.placedCount);
  }
};

const CapacityGraph = function() {
  class CapacityGraph {
    constructor(el, animate) {
      var maxCapacity, stats;
      this.animate = animate;
      ({ maxCapacity, monthStats: this.monthStats } = $(el).data());
      this.maxCapacity = maxCapacity === void 0 ? null : maxCapacity;
      this.monthStats = function() {
        var j, len, ref, results;
        ref = this.monthStats;
        results = [];
        for (j = 0, len = ref.length; j < len; j++) {
          stats = ref[j];
          results.push(new MonthStats(stats, this.maxCapacity));
        }
        return results;
      }.call(this);
      this.chart = d3
        .select($(el)[0])
        .attr("height", this.barHeight)
        .attr("width", this.graphWidth());
      this.draw();
      this.tooltip();
    }

    draw() {
      this.drawBars();
      return this.drawMaxLine();
    }

    drawBars() {
      var bar;
      bar = this.chart
        .selectAll("g")
        .data(this.monthStats)
        .enter()
        .append("g")
        .attr("class", "bar")
        .attr("data-id", function(d, i) {
          return i;
        })
        .attr("transform", (d, i) => {
          return `translate(${i * this.barWidth * 2 + this.barWidth / 2}, 0)`;
        });
      if (this.animate) {
        bar
          .attr("transform", (d, i) => {
            return `translate(${i * this.barWidth * 2 + this.barWidth / 2}, ${
              this.barHeight
            })scale(1, 0)`;
          })
          .style("opacity", 0);
        bar
          .transition()
          .duration(400)
          .delay(function(d, i) {
            return i * 50;
          })
          .style("opacity", 1)
          .attr("transform", (d, i) => {
            return `translate(${i * this.barWidth * 2 +
              this.barWidth / 2}, 0)scale(1)`;
          });
      }
      bar
        .append("rect")
        .attr("class", "waiting-list")
        .attr("y", d => {
          return this.y(d.cumulativeWaitingList());
        })
        .attr("height", d => {
          return this.barHeight - this.y(d.cumulativeWaitingList());
        })
        .attr("width", this.barWidth);
      bar
        .append("rect")
        .attr("class", "placed-candidates")
        .attr("y", d => {
          return this.y(d.cumulativePlacedCandidates());
        })
        .attr("height", d => {
          return this.barHeight - this.y(d.cumulativePlacedCandidates());
        })
        .attr("width", this.barWidth);
      return bar
        .append("rect")
        .attr("class", "placed")
        .attr("y", d => {
          return this.y(d.placedCount);
        })
        .attr("height", d => {
          return this.barHeight - this.y(d.placedCount);
        })
        .attr("width", this.barWidth);
    }

    drawMaxLine() {
      var line;
      line = this.chart
        .append("g")
        .append("rect")
        .attr("class", "max-line")
        .attr("width", this.graphWidth())
        .attr("height", 1)
        .attr("y", () => {
          return this.y(this.maxCapacity);
        });
      if (this.animate) {
        line.style("opacity", 0).attr("y", this.barHeight / 2);
        return line
          .transition()
          .delay(300)
          .duration(800)
          .style("opacity", 1)
          .attr("y", () => {
            return this.y(this.maxCapacity);
          });
      }
    }

    tooltip() {
      var that;
      that = this;
      return $(".bar").tooltip({
        placement: "bottom",
        container: "body",
        html: true,
        title: function() {
          var availableCandidates,
            monthStats,
            placed,
            placedCandidates,
            stats,
            waitingList;
          monthStats = that.monthStats[$(this).data("id")];
          waitingList = that.maxCapacity
            ? `<div class='capacity-tooltip__stats--waiting-list'>\n  <strong>${monthStats.waitinglist()}</strong> Wachtlijst\n</div>`
            : `<div class='capacity-tooltip__stats--placed-candidates'>\n  <strong>${monthStats.waitinglist()}</strong> Kandidaten\n</div>`;
          placedCandidates = `<div class='capacity-tooltip__stats--placed-candidates'>\n  <strong>${monthStats.placedCandidates()}</strong> Geplaatste kandidaten\n</div>`;
          placed = `<div class='capacity-tooltip__stats--placed'>\n  <strong>${
            monthStats.placedCount
          }</strong> Geplaatst\n</div>`;
          availableCandidates = `<div class='capacity-tooltip__stats--free'>\n  <strong>${monthStats.availableWithCandidates()}</strong> Beschikbaar\n</div>`;
          stats = that.maxCapacity
            ? waitingList + placedCandidates + placed + availableCandidates
            : waitingList + placed;
          return `<div class='capacity-tooltip'>\n  <div class='capacity-tooltip__title'>Verwachting ${
            monthStats.month
          }</div>\n  ${stats}\n</div>`;
        }
      });
    }

    y(number) {
      return (this._y ||
        (this._y = d3
          .scaleLinear()
          .domain([0, this.heighestPoint()])
          .rangeRound([this.barHeight, 0])))(number);
    }

    graphWidth() {
      return this.monthStats.length * this.barWidth * 2;
    }

    heighestPoint() {
      return Math.max(this.heighestBar(), this.maxCapacity);
    }

    heighestBar() {
      return (
        this._heighestBar ||
        (this._heighestBar = d3.max(this.monthStats, function(d) {
          return d.placedCount + d.candidateCount;
        }))
      );
    }
  }

  CapacityGraph.prototype.barHeight = 58;

  CapacityGraph.prototype.barWidth = 8;

  return CapacityGraph;
}.call(this);

export default $(document).on("turbolinks:load", function() {
  var graphs;
  graphs = $(".js-capacity-graph");
  return graphs.each(function() {
    return new CapacityGraph(this, graphs.length === 1);
  });
});
