const HideFullEvent = class HideFullEvent {
  constructor() {
    $(".stream__item--event").on("click", ".js-show-more", function(e) {
      var event;
      event = $(e.delegateTarget);
      $(this).hide();
      event.find(".js-short").remove();
      return event.find(".js-full").slideDown();
    });
  }
};

export default () => new HideFullEvent();
