function todos(context) {
  var showLastFour;
  showLastFour = function() {
    var el;
    el = $(context.getElement());
    if (!el.data("todoStream")) {
      return;
    }
    return el
      .find(".is-incompleted")
      .hide()
      .slice(0, 4)
      .show();
  };
  return {
    messages: ["updatetodo"],
    init: function() {
      return showLastFour();
    },
    onmessage: function(name, payload) {
      showLastFour();
      return setTimeout(function() {
        return $(context.getElement())
          .find(`[data-todo-id=${payload.id}]`)
          .click();
      }, 1);
    }
  };
}

export default {
  name: "todos",
  fn: todos
};
