function inputToggle(context) {
  var updateHTML;
  updateHTML = function() {
    var el;
    el = $(context.getElement());
    if (el.is(":checked")) {
      return el.next().show();
    } else {
      return el
        .next()
        .val("")
        .hide();
    }
  };
  return {
    init: function() {
      return updateHTML();
    },
    onclick: function() {
      return updateHTML();
    }
  };
}

export default {
  name: "input-toggle",
  fn: inputToggle
};
