import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["comments", "input"];

  toggle(event) {
    event.preventDefault();

    if (this.commentsTarget.children.length > 0) {
      this.commentsTarget.classList.toggle("hidden");
    } else {
      this.stimulate("Comments#list", this.commentsTarget);
    }
  }

  createSuccess() {
    this.inputTarget.value = "";
  }
}
