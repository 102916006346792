// When a client registers (or is registered), a preferred city
// has to be selected. The possible cities are different for each service,
// so this module ensures only the cities offering the selected service
// are available in a dropdown.
//
// This is done as follows: the Rails view renders a dropdown for each available
// service. This JS module removes them all, and shows/hides the appropriate field(s)
// when a service is selected.

function preferenceSelect(context) {
  let STATE = {
    allServices: [], // All available services - loaded on init.
    cityFields: null, // The dropdowns are removed from the DOM and stored here
    parent: null, // The parent element to which the active dropdown is attached
    service: null, // The currently selected service (keyword)
    prefix: null // The prefix for the radio button element id
  };
  const allServices = window.appState.services.map(service => service.key);
  const parent = context.element;
  const cityListsByService = allServices.reduce((allElements, service) => {
    const cityList = document.querySelector(
      `[data-type='preference-${service}']`
    );

    if (!!cityList) {
      allElements[service] = parent.removeChild(cityList);
    }

    return allElements;
  }, {});

  const checkRadioButtonStatus = service => {
    const button = document.querySelector(
      `#${STATE.prefix}_service_${service}`
    );

    return button && button.checked;
  };

  const getSelectedService = () => {
    const serviceButtonStatus = STATE.allServices.map(service => ({
      serviceName: service,
      active: checkRadioButtonStatus(service)
    }));
    const selectedService = serviceButtonStatus.find(button => button.active);

    return selectedService ? selectedService.serviceName : null;
  };

  const updateCitiesDropdown = () => {
    const newService = getSelectedService();

    if (!newService || newService === STATE.service) return;

    const targetElement = document.querySelector(".js-preference");
    const oldService = STATE.service;

    targetElement.insertBefore(STATE.cityFields[newService], null);

    if (!!oldService) {
      STATE.cityFields[oldService] = targetElement.removeChild(
        document.querySelector(`[data-type='preference-${oldService}']`)
      );
    }

    STATE.service = newService;

    App.broadcast("service-selected", newService);
  };

  const init = () => {
    const placeholder = document.createElement("div");
    placeholder.className = "js-preference";

    STATE.parent.appendChild(placeholder);
    updateCitiesDropdown();
  };

  const handleChange = (_event, _element, elementType) => {
    if (elementType !== "input") {
      return;
    }

    updateCitiesDropdown();
  };

  STATE = Object.assign(STATE, {
    parent: parent,
    cityFields: cityListsByService,
    prefix: parent.dataset.prefix,
    allServices: allServices
  });

  return {
    init: init,
    onchange: handleChange
  };
}

export default {
  name: "preference-select",
  fn: preferenceSelect
};
