import Pikaday from "pikaday";
import "moment";

function datePicker(context) {
  const MONTHS = [
    "januari",
    "februari",
    "maart",
    "april",
    "mei",
    "juni",
    "juli",
    "augustus",
    "september",
    "oktober",
    "november",
    "december"
  ];

  const WEEKDAYS = [
    "Zondag",
    "Maandag",
    "Dinsdag",
    "Woensdag",
    "Donderdag",
    "Vrijdag",
    "Zaterdag"
  ];

  const WEEKDAYS_SHORT = ["Zo", "Ma", "Di", "Wo", "Do", "Vr", "Za"];

  function renderDatepicker(context) {
    const el = context.getElement();
    const startYear = el.dataset.startYear || 1900;
    const endYear = el.dataset.endYear || new Date().getFullYear();

    return new Pikaday({
      field: el,
      format: "D/MM/YYYY",
      firstDay: 1,
      yearRange: [startYear, endYear],
      i18n: {
        previousMonth: "Vorige maand",
        nextMonth: "Volgende maand",
        months: MONTHS,
        weekdays: WEEKDAYS,
        weekdaysShort: WEEKDAYS_SHORT
      }
    });
  }

  return {
    init: renderDatepicker(context)
  };
}

export default {
  name: "date-picker",
  fn: datePicker
};
