function toggleOnCheck(context) {
  const render = () => {
    const el = context.element;
    const checkboxes = el.querySelectorAll('[data-type="checkbox"]');
    const toggleable = el.querySelector('[data-type="toggleable"]');

    // Check if any checkboxes are checked. Sometimes there are two,
    // hence the use of querySelectorAll.
    const checked = Array.from(checkboxes).some(box => box.checked);

    toggleable.style.display = checked ? null : "none";
  };

  return {
    init: render,
    onchange: render
  };
}

export default {
  name: "toggle-on-check",
  fn: toggleOnCheck
};
