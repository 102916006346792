const DeleteNestedModel = class DeleteNestedModel {
  constructor() {
    $("form").on("click", "[data-behaviour=delete_nested_model]", this.onClick);
  }

  onClick(e) {
    e.preventDefault();
    $(this)
      .prev("input[type=hidden]")
      .val("1");
    return $(this)
      .closest(".fields")
      .slideUp();
  }
};

export default $(document).on("turbolinks:load ajaxComplete", function() {
  return new DeleteNestedModel();
});
