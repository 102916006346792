import ApplicationController from "./application_controller";
import feather from "feather-icons";

export default class extends ApplicationController {
  static targets = ["form"];

  toggle(_event) {
    const form = this.formTarget;

    form.classList.toggle("hidden");
  }

  applyFilters(event) {
    event.preventDefault();

    const filtersElement = document.getElementById("filters");
    filtersElement.classList.toggle("hidden");

    this.stimulate("Filter#apply_filters", event.target);
  }
}
