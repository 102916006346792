const ActiveState = class ActiveState {
  constructor() {
    this.data = window.appState;

    if (!this.data) {
      return;
    }

    this.filter();
    this.clientMenu();
    this.removeCopy();
    this.sideBar();
    this.eventFilters();
    this.setCurrentEventFilter();
  }

  filter() {
    return $("[data-filter]").each((_index, el) => {
      var $filter,
        empty,
        filterLabel,
        filterLabels,
        filterNames,
        filterValue,
        markAsActive,
        multiLabel,
        prefix,
        value;
      $filter = $(el);
      filterNames = $filter.data("filter").split("|");
      empty = $filter.data("filter-empty") || "Alle";
      filterValue = this.data.q
        ? this.data.q[filterNames[0]] || this.data.q[filterNames[1]]
        : empty;
      prefix = () => {
        if (!this.data.q || filterNames.length === 1) {
          return "";
        }
        if (this.data.q[filterNames[0]]) {
          return filterNames[0] + "-";
        } else {
          return filterNames[1] + "-";
        }
      };

      // find the element(s) corresponding to a filtervalue and mark them with a class
      // return the element(s)
      markAsActive = function(filterValue, $filter) {
        var $active;
        $active = $(`[data-filter-value='${prefix()}${filterValue}']`, $filter);
        $active.addClass("is-active");
        return $active;
      };

      multiLabel = function(labels) {
        if (labels.length > 1) {
          return `${labels.length} geselecteerd`;
        } else {
          return labels; // coffeescript will do the right thing for an array of size 0 or 1.
        }
      };

      if (filterValue instanceof Array) {
        filterLabels = (function() {
          var i, len, results;
          results = [];
          for (i = 0, len = filterValue.length; i < len; i++) {
            value = filterValue[i];
            results.push(markAsActive(value, $filter).data("filter-label"));
          }
          return results;
        })();
        filterLabel = multiLabel(filterLabels);
      } else {
        filterLabel = markAsActive(filterValue, $filter).data("filter-label");
      }

      function truncate(string) {
        if (!string) return;

        return string.length > 15 ? string.substr(0, 14) + "…" : string;
      }

      return $(".filters__label", $filter).text(
        truncate(filterLabel) || filterValue
      );
    });
  }

  clientMenu() {
    if (this.data.controller === "clients") {
      return $(`.js-client-menu-${this.data.id}`).addClass("is-active");
    } else if (this.data.client_id || this.data.team_id) {
      return $(
        `.js-client-menu-${this.data.client_id || this.data.team_id}`
      ).addClass("is-active");
    } else {
      return $(".js-client-menu-house").addClass("is-active");
    }
  }

  removeCopy() {
    if (this.data.controller === "houses") {
      return $(".stream__copy").remove();
    }
  }

  sideBar() {
    $(`.js-sidebar-${this.data.controller}`).addClass("is-active");
    $(`.js-action-${this.data.action}`).addClass("is-active");
    if (
      this.data.action === "show" &&
      (this.data.controller === "houses" || this.data.controller === "clients")
    ) {
      $(".js-sidebar-events").addClass("is-active");
    }
    if (
      this.data.controller === "intake_surveys" ||
      this.data.controller === "exit_surveys"
    ) {
      return $(".js-sidebar-yearly_surveys").addClass("is-active");
    }
  }

  eventFilters() {
    return $(".js-event-filter-" + this.currentEventFilterKey()).addClass(
      "is-active"
    );
  }

  setCurrentEventFilter() {
    if (this.data.current_event_filter_name !== void 0) {
      return $(".js-current-event-filter-name").text(
        this.data.current_event_filter_name
      );
    }
  }

  currentEventFilterKey() {
    if (this.data.placed_on_file_eq !== void 0) {
      return "placed_on_file";
    } else if (this.data.event_type_eq !== void 0) {
      return this.data.event_type_eq;
    } else {
      return "undefined";
    }
  }
};

export default $(document).on("turbolinks:load", function() {
  return new ActiveState();
});
