import Quill from "quill/core";

import Toolbar from "quill/modules/toolbar";

import Bold from "quill/formats/bold";
import Italic from "quill/formats/italic";
import Link from "quill/formats/link";
import List, { ListItem } from "quill/formats/list";

Quill.register({
  "modules/toolbar": Toolbar,
  "formats/bold": Bold,
  "formats/italic": Italic,
  "formats/link": Link,
  "formats/list": List,
  "formats/list-item": ListItem
});

export default Quill;
