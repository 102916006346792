const EditPhase = class EditPhase {
  constructor() {
    $("#client_phase_id")
      .change(this.onChange)
      .change();
  }

  onChange(e) {
    var selectedPhase;
    selectedPhase = $(e.currentTarget).val();
    if (selectedPhase === "50" || selectedPhase === "40") {
      $("#js-phase-house").show();
    } else {
      $("#js-phase-house").hide();
      $("#client_house_id").val("");
    }
    $("#js-phase-city").toggle(
      selectedPhase !== "50" && selectedPhase !== "60"
    );
    $("#js-phase-start-date").toggle(selectedPhase === "50");
    return $("#js-phase-archive").toggle(selectedPhase === "60");
  }
};

export default $(document).on("turbolinks:load", function() {
  return new EditPhase();
});
