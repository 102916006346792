export default $(document).on("turbolinks:load ajaxComplete", function() {
  var counter, input;
  input = $("#objective_name");
  counter = $(".objective_name .hint");
  return input.keyup(function() {
    var len;
    len = $(this).val().length;
    return counter.text(`(${len}/160)`);
  });
});
