function subscribers(context) {
  return {
    onchange: function(_event, element, elementType) {
      var isChecked;
      if (elementType !== "toggle") {
        return;
      }
      isChecked = $(element).is(":checked");
      return $(context.getElement())
        .find("[data-house-employee=true]")
        .prop("checked", isChecked);
    }
  };
}

export default {
  name: "subscribers",
  fn: subscribers
};
