const TodoShift = class TodoShift {
  constructor() {
    var $shift;
    $shift = $(".js-shift");
    $shift.change(function() {
      var isEmpty;
      isEmpty = $(this).val() === "";
      return $(".js-time").toggle(isEmpty);
    });
    $shift.change();
  }
};

export default $(document).on("turbolinks:load ajaxComplete", function() {
  return new TodoShift();
});
