function todo(context) {
  return {
    onclick: function() {
      var commentPopup, el;
      el = $(context.getElement());
      commentPopup = el.find(".todo__comment_popup");
      commentPopup.slideDown(200);
      return el
        .find("input")
        .focus()
        .one("blur", function() {
          return setTimeout(function() {
            return commentPopup.hide();
          }, 200);
        });
    }
  };
}

export default {
  name: "todo",
  fn: todo
};
