function startDate(context) {
  var datePicker, onChange;
  datePicker = void 0;
  onChange = function(val, clearInput = false) {
    var showSpecific;
    showSpecific = !(val === "" || val === "1990-01-01");
    if (showSpecific) {
      $(context.getElement()).append(datePicker);
      if (clearInput) {
        return datePicker
          .find("input")
          .val("")
          .pikaday("setMoment", moment());
      }
    } else {
      return datePicker.detach();
    }
  };
  return {
    init: function() {
      var $el, val;
      $el = $(context.getElement());
      datePicker = $el.find(".date-picker");
      val = $el.find("select").val();
      return onChange(val);
    },
    onchange: function(event, element, elementType) {
      if (elementType !== "select") {
        return;
      }
      return onChange($(element).val(), {
        clearInput: true
      });
    }
  };
}

export default {
  name: "start-date",
  fn: startDate
};
