function dateFilters(context) {
  return {
    init: function() {
      var el;
      el = $(context.getElement());
      return el.find(".period-filter").hide();
    },
    onclick: function(event, element, elementType) {
      var el;
      if (elementType !== "btn") {
        return;
      }
      event.preventDefault();
      event.stopPropagation();
      el = $(context.getElement());
      el.find(".period-filter").show();
      return el.find(".date-filters").hide();
    }
  };
}

export default {
  name: "date-filters",
  fn: dateFilters
};
