function documentFolder(context) {
  return {
    onclick: function(_event, _element, elementType) {
      if (elementType !== "button") {
        return;
      }
      return $(context.getElement()).toggleClass("is-visible");
    }
  };
}

export default {
  name: "document-folder",
  fn: documentFolder
};
