import * as Chart from "chart.js";

function todoChart(context) {
  let chart = null;

  function renderChart() {
    const el = $(context.getElement());

    if (!el.is(":visible")) {
      return;
    }

    const done = el.data("done");
    const failed = el.data("failed");
    const notApplicable = el.data("notApplicable");
    const open = el.data("open");
    const total = done + failed + notApplicable + open;
    const config = {
      type: "doughnut",
      data: {
        datasets: [
          {
            data: [
              done / total,
              failed / total,
              notApplicable / total,
              open / total
            ],
            backgroundColor: ["#1bcfb4", "#fe8676", "#4a515e", "#eff0f3"]
          }
        ]
      },
      options: {
        legend: {
          display: false
        },
        title: {
          display: false
        },
        animation: false,
        responsive: false,
        tooltips: {
          enabled: false
        }
      }
    };

    return new Chart(el[0].getContext("2d"), config);
  }

  function updateChart() {
    if (!!chart) chart.destroy();

    chart = renderChart();
  }

  return {
    messages: ["updatetodo"],
    init: updateChart,
    onmessage: updateChart,
    onclick: function(event) {
      event.stopPropagation();
      updateChart();
    }
  };
}

export default {
  name: "todo-chart",
  fn: todoChart
};
