import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  connect() {
    window.requestAnimationFrame(() => {
      window.requestAnimationFrame(() => {
        this.element.classList.add(
          "translate-y-0",
          "opacity-100",
          "sm:translate-x-0"
        );
      });
    });
  }

  close(event) {
    event.preventDefault();
    this.element.ontransitionend = () => this.element.remove();
    this.element.classList.remove("opacity-100");
  }
}
