import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["legal", "chosen"];

  updateInput(_event) {
    const firstLegalName = this.legalTarget.value.split(" ")[0];
    const firstChosenName = this.chosenTarget.value;

    // Return if there is already a chosen name
    if (!firstLegalName || !!firstChosenName) return;

    this.chosenTarget.value = firstLegalName;
  }
}
