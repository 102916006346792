function page(_context) {
  var checkInternetExplorer, removeSpinner, showSpinner;
  showSpinner = function() {
    $(".l-content").append($("<div id='js-overlay'/>"));
    return $("body").append($("<div id='js-overlay__spinner'/>"));
  };
  removeSpinner = function() {
    $("#js-overlay").remove();
    return $("#js-overlay__spinner").remove();
  };
  checkInternetExplorer = function() {
    if (navigator.userAgent.match(/msie/i)) {
      return $("html").addClass("ie");
    }
  };
  return {
    messages: ["updatestate", "showspinner", "hidespinner"],
    init: function() {
      return checkInternetExplorer();
    },
    onmessage: function(name, payload) {
      switch (name) {
        case "updatestate":
          return (window.appState = payload.appState);
        case "showspinner":
          return showSpinner();
        case "hidespinner":
          return removeSpinner();
      }
    }
  };
}

export default {
  name: "page",
  fn: page
};
