function streamDates(context) {
  var addDates, getEvents, removeDates;
  getEvents = function() {
    var $el, date, el, firstEvents, i, len, ref;
    firstEvents = {};
    ref = $(context.getElement()).find(".stream__item");
    for (i = 0, len = ref.length; i < len; i++) {
      el = ref[i];
      $el = $(el);
      date = $el.data("date");
      if (firstEvents[date] == null) {
        firstEvents[date] = el;
      }
    }
    return firstEvents;
  };
  removeDates = function() {
    return $(context.getElement())
      .find(".stream__item--date")
      .remove();
  };
  addDates = function(events) {
    var date, element, results;
    results = [];
    for (date in events) {
      element = events[date];
      results.push(
        $(
          `<div class='stream__item stream__item--date'> <div class='stream__left'> <div class='stream__icon'></div> </div> <div class='stream__date'>${date}</div> </div>`
        ).insertBefore(element)
      );
    }
    return results;
  };
  return {
    messages: ["updateevents"],
    init: function() {
      return addDates(getEvents());
    },
    onmessage: function(name, payload) {
      if (payload.page) {
        $("#events").append(payload.events);
      } else {
        $("#events").html(payload.events);
      }
      $("#next-page").html(payload.loadMoreLink);
      removeDates();
      return addDates(getEvents());
    }
  };
}

export default {
  name: "stream-dates",
  fn: streamDates
};
