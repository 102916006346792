import ApplicationController from "./application_controller";
import feather from "feather-icons";

export default class extends ApplicationController {
  static targets = ["menu", "icon"];

  toggle(event) {
    event.preventDefault();

    const newIcon = document.createElement("i");

    if (this.menuTarget.classList.contains("hidden")) {
      newIcon.dataset.feather = "x";
    } else {
      newIcon.dataset.feather = "menu";
    }

    this.iconTarget.replaceChildren(newIcon);
    feather.replace();
    this.menuTarget.classList.toggle("hidden");
    this.iconTarget.classList.toggle('rotate-90');
  }
}
