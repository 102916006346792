import { DirectUpload } from "@rails/activestorage";

const createHiddenField = (name, signed_id) => {
  const inputField = document.createElement("input");

  inputField.setAttribute("type", "hidden");
  inputField.setAttribute("value", signed_id);
  inputField.name = name;

  return inputField;
};

const loadController = controller => {
  const button = controller.querySelector(
    '[data-target="direct-upload.button"]'
  );
  const field = controller.querySelector('[data-target="direct-upload.field"]');
  const spinner = controller.querySelector(
    '[data-target="direct-upload.spinner"]'
  );
  const filename = controller.querySelector(
    '[data-target="direct-upload.filename"]'
  );

  const uploadFile = file => {
    const url = field.dataset.directUploadUrl;
    const upload = new DirectUpload(file, url);

    show(spinner);
    hide(button);

    filename.textContent = "";

    upload.create((error, blob) => {
      if (error) {
        hide(spinner);
        show(button);

        alert("Bestand uploaden mislukt. Probeer opnieuw.");
      } else {
        hide(spinner);
        show(button);

        filename.textContent = blob.filename;

        const hiddenField = createHiddenField(field.name, blob.signed_id);

        controller.closest("form").appendChild(hiddenField);
      }
    });
  };

  const show = element => {
    element.style.display = "inline-block";
  };

  const hide = element => {
    element.style.display = "none";
  };

  button.addEventListener("click", _event => {
    field.click();
  });

  field.addEventListener("change", _event => {
    Array.from(field.files).forEach(file => uploadFile(file));
    field.value = null;
  });
};

function loadControllers() {
  const controllers = document.querySelectorAll(
    '[data-controller="direct-upload"]'
  );

  if (controllers === []) return;

  controllers.forEach(controller => loadController(controller));
}

export default function() {
  document.addEventListener("turbolinks:load", loadControllers);
}
