import Quill from "./quill";

export default class Editor {
  constructor(id) {
    this.updateInput = this.updateInput.bind(this);
    this.editor = new Quill(`.editor-id-${id} .editor-content`, {
      formats: ["bold", "italic", "link", "bullet", "list"],
      styles: false,
      modules: {
        toolbar: `.editor-id-${id} .editor-toolbar`
      }
    });
    this.input = $(`.editor-id-${id} input`);
    this.onChange(this.updateInput);
  }

  updateInput() {
    this.input.val(this.getHTML());
  }

  onChange(callback) {
    return this.editor.on("text-change", callback);
  }

  getHTML() {
    return this.editor.root.innerHTML;
  }

  setHTML(value) {
    this.editor.root.innerHTML = value;
  }

  focus() {
    this.editor.focus();
  }
}
