function toggleOnClick(context) {
  var content;
  content = function() {
    return $(context.getElement()).find('[data-type="content"]');
  };
  return {
    init: function() {
      return content().hide();
    },
    onclick: function(event, element, elementType) {
      if (elementType !== "btn") {
        return;
      }
      event.preventDefault();
      $(element).toggleClass("is-toggled");
      return $(content()).toggle();
    }
  };
}

export default {
  name: "toggle-on-click",
  fn: toggleOnClick
};
