const ContactItem = {
  show: function(options) {
    $(".js-contact-show").html(options.body);
    return this._setActive(options.id);
  },
  edit: function(options) {
    $(".js-contact-show")
      .html(options.body)
      .removeClass("animate-highlight");
    App.startAll(document.documentElement);
  },
  update: function(options) {
    $(".js-contact-show")
      .html(options.body)
      .addClass("animate-highlight");
    $(`#contact-${options.id}`).replaceWith(options.list_body);
    return this._setActive(options.id);
  },
  _setActive: function(id) {
    $(".contact").removeClass("is-active");
    return $(`#contact-${id}`).addClass("is-active");
  }
};

export default ContactItem;
