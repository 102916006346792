import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "select", "input", "wrapper" ]

  connect() {
    if (this.selectTarget.value !== "") {
      this.enableInput();
    }
  }

  change() {
    if (this.selectTarget.value !== "") {
      this.enableInput();
    } else {
      this.disableInput();
    }
  }

  enableInput() {
    this.wrapperTarget.classList.remove("hidden");
  }

  disableInput() {
    this.wrapperTarget.classList.add("hidden");
    this.inputTarget.value = "";
  }
}
