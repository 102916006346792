import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["toggle", "rotate", "expand"];

  toggle(event) {
    event.preventDefault();

    this.toggleTarget.classList.toggle("hidden");
    this.rotateTarget.classList.toggle("rotate-180");
  }

  toggleAccordeon(event) {
    event.preventDefault();

    this.expandTarget.classList.toggle("max-h-80");
    this.expandTarget.classList.toggle("max-h-12");
    this.expandTarget.classList.toggle("overflow-hidden");
    this.expandTarget.classList.toggle("overflow-y-auto");
  }
}
