const CollapseComments = class ColapseComments {
  constructor() {
    this.hideComments();
    this.bindClick();
  }

  hideComments() {
    return $(".stream__comments-list").each(function() {
      var comments, length;
      comments = $(".comment", this);
      length = comments.length;
      if (comments.length > 3) {
        comments.slice(0, length - 3).hide();
        return $(this)
          .parent()
          .parent()
          .find(".stream__show-comments")
          .show();
      }
    });
  }

  bindClick() {
    return $(".js-show-comments").on("click", function() {
      $(this)
        .parent()
        .find(".comment")
        .show();
      return $(this).hide();
    });
  }
};

export default $(document).on(
  "turbolinks:load ajaxComplete",
  () => new CollapseComments()
);
