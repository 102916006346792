function serviceFeature(context) {
  const allServices = window.appState.services;
  const keyedServices = allServices.reduce(
    (result, service) => ((result[service.key] = service), result),
    {}
  );
  const parent = context.element;
  const feature = context.element.dataset.feature;
  let removedFeature = null;

  function disableFeature() {
    removedFeature = parent.removeChild(parent.querySelector("div"));
  }

  function enableFeature() {
    parent.appendChild(removedFeature);
    removedFeature = null;
  }

  function handleChange(_name, payload) {
    const service = keyedServices[payload];

    if (service.features.includes(feature) && !!removedFeature) {
      enableFeature();
    } else if (!service.features.includes(feature) && !removedFeature) {
      disableFeature();
    }
  }

  function init() {
    disableFeature();
  }

  return {
    messages: ["service-selected"],
    init: init,
    onmessage: handleChange
  };
}

export default {
  name: "service-feature",
  fn: serviceFeature
};
