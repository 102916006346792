import { Controller } from "@hotwired/stimulus";

// This controller attaches to a select input.
// It hides form elements if the selected value
// is not present in their dataset.
export default class extends Controller {
  static targets = ["select", "formElement"];

  connect() {
    this.toggle()
  }

  toggle() {
    const selectedValue = this.selectTarget.value;
    this.formElementTargets.forEach(el => {
      const selectValues = this.parseValues(el.dataset.selectValue)
      if (selectValues.includes(selectedValue)) {
        el.classList.remove("hidden");
      } else {
        el.classList.add("hidden");
        el.querySelector("input").value = null;
      }
    });
  }

  parseValues(values) {
    const parsedValues = JSON.parse(values)
    return Array.isArray(parsedValues) ? parsedValues : [parsedValues]
  }
}
