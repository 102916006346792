function indicationForm(context) {
  var updateHTML;
  updateHTML = function() {
    var active, el;
    el = $(context.getElement());
    active = el.find("[data-type=status]").val() === "3";
    return el.find(".indication_end_date .date-picker").toggle(active);
  };
  return {
    init: function() {
      return updateHTML();
    },
    onchange: function(event, element, elementType) {
      if (elementType !== "status") {
        return;
      }
      return updateHTML();
    }
  };
}

export default {
  name: "indication-form",
  fn: indicationForm
};
