function downloadModule(context) {
  // minutes after which polling for download stops
  const TIMEOUT_MINUTES = 30;

  function prepareDownload(element) {
    // don't download the same file twice
    if (element.hasClass("generate-download-pending")) return;
    // respect data-confirm attribute
    if (element.data("confirm") && !confirm($el.data("confirm"))) return;

    // set deadline and start polling, show spinner
    // hide spinner and start download when done
    element.addClass("generate-download-pending");
    element.children("span").text("Bezig...");

    const deadline = new Date();

    deadline.setMinutes(deadline.getMinutes() + TIMEOUT_MINUTES);

    return pollStatus(element.attr("href"), deadline)
      .done(download_url => {
        window.location = download_url;
      })
      .fail(() => {
        alert("Het maken van het document is mislukt. Probeer opnieuw.");
      })
      .always(() => {
        element.children("span").text("Klik om te downloaden");
        element.removeClass("generate-download-pending");
      });
  }

  // repeatedly polls the generated_downloads API until the file is ready, then downloads it
  function pollStatus(status_url, deadline) {
    return $.Deferred(({ reject, resolve }) => {
      setTimeout(() => {
        // break loop if deadline has passed
        if (new Date() > deadline) {
          return reject();
        }

        // check the status, if it is done show the file, otherwise continue polling
        $.getJSON(status_url)
          .fail(reject)
          .done(response => {
            if (response.status === "completed") {
              return resolve(response.download_url);
            } else {
              return pollStatus(response.status_url, deadline)
                .fail(reject)
                .done(resolve);
            }
          });
      }, 1000);
    });
  }

  return {
    init: () => {
      const element = $(context.getElement());

      return prepareDownload(element);
    },
    onclick: event => {
      event.preventDefault();
      event.stopPropagation();
      const element = $(context.getElement());

      return prepareDownload(element);
    }
  };
}

export default {
  name: "generate-download",
  fn: downloadModule
};
