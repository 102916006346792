function client(context) {
  var renderFavorite;
  renderFavorite = function() {
    var el, id, isFavorite;
    el = $(context.getElement());
    id = el.data("clientId");
    isFavorite = appState.favorites.indexOf(id) !== -1;
    return el.toggleClass("is-favorite", isFavorite);
  };
  return {
    messages: ["favoritesupdated"],
    init: function() {
      return renderFavorite();
    },
    onmessage: function() {
      return renderFavorite();
    }
  };
}

export default {
  name: "client",
  fn: client
};
