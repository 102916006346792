import Box from "t3js/dist/t3";

import AutoSaveField from "./auto_save_field";
import CommentForm from "./comment_form";
import DateFilters from "./date_filters";
import DatePicker from "./date_picker";
import DestroyClientButton from "./destroy_client_button";
import DocumentFolder from "./document_folder";
import Favorites from "./favorites";
import GeneratedDownload from "./generate_download";
import IndicationForm from "./indication_form";
import InputToggle from "./input_toggle";
import LegalRepresentativeForm from "./legal_representative_form";
import MainMenu from "./main_menu";
import NewClientLogin from "./new_client_login";
import Page from "./page";
import PhotoZoom from "./photo_zoom";
import PreferenceSelect from "./preference_select";
import ServiceFeature from "./service_feature";
import StartDate from "./start_date";
import StreamDates from "./stream_dates";
import Subscribers from "./subscribers";
import Todo from "./todo";
import TodoChart from "./todo_chart";
import Todos from "./todos";
import ToggleOnCheck from "./toggle_on_check";
import ToggleOnClick from "./toggle_on_click";

const modules = [
  AutoSaveField,
  CommentForm,
  DateFilters,
  DatePicker,
  DestroyClientButton,
  DocumentFolder,
  Favorites,
  GeneratedDownload,
  IndicationForm,
  InputToggle,
  LegalRepresentativeForm,
  MainMenu,
  NewClientLogin,
  Page,
  PhotoZoom,
  PreferenceSelect,
  ServiceFeature,
  StartDate,
  StreamDates,
  Subscribers,
  Todo,
  TodoChart,
  Todos,
  ToggleOnCheck,
  ToggleOnClick
];

const App = Box.Application;

modules.map(module => {
  App.addModule(module.name, module.fn);
});

export default App;
