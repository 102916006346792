import "jquery";
import "jquery-ui";
import "popper.js";
import "bootstrap";
import "t3js";
import "@oddcamp/cocoon-vanilla-js";
import * as ActiveStorage from "@rails/activestorage";
import * as UJS from "@rails/ujs";
import * as Turbolinks from "turbolinks";

import App from "../src/box_modules/box_app";
import * as OldModules from "../src/old_modules";
import EventForm from "../src/utils/event_form";
import applyDropdown from "../src/plugins/bootstrap.dropdown.custom";

const { ContactItem, enableDirectUploads, ShowFullEvent } = OldModules;

// Start Rails modules
UJS.start();
Turbolinks.start();
ActiveStorage.start();
enableDirectUploads();

// Extend jQuery with custom dropdown
applyDropdown($);

// Set window objects
window.$ = $;
window.jQuery = $;
window.EventForm = EventForm;
window.App = App;
window.ContactItem = ContactItem;
window.ShowFullEvent = ShowFullEvent;

// Start Box App, set up event listeners
$(document)
  .on("ready", function() {
    App.init({
      debug: true
    });
  })
  .on("turbolinks:request-start", function() {
    App.broadcast("showspinner");
    App.stopAll(document.documentElement);
  })
  .on("turbolinks:load", function() {
    App.broadcast("hidespinner");
    ShowFullEvent();
    App.startAll(document.documentElement);
  })
  .on("ajaxComplete ajax:complete cocoon:after-insert", function() {
    App.startAll(document.documentElement);
  });

import "controllers"
