function commentForm(context) {
  return {
    onclick: function(event, element, elementType) {
      if (elementType !== "cancel-btn") {
        return;
      }
      event.preventDefault();
      return $(context.getElement()).remove();
    }
  };
}

export default {
  name: "comment-form",
  fn: commentForm
};
