import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "select", "input" ]

  connect() {
    const currentValue = this.inputTarget.value;
    const options = Array.from(this.selectTarget.options).map(option => option.value);
    if (currentValue && options.indexOf(currentValue) < 0) {
      this.selectTarget.value = "Anders nl."
      this.enableInput(currentValue);
    }
  }

  change() {
    if (this.selectTarget.value === "Anders nl.") {
      this.enableInput("");
    } else {
      this.inputTarget.classList.add("hidden");
      this.inputTarget.value = "";
      this.inputTarget.setAttribute("disabled", "")
    }
  }

  enableInput(value) {
    this.inputTarget.removeAttribute("disabled");
    this.inputTarget.value = value;
    this.inputTarget.classList.remove("hidden");
  }
}
