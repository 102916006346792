const SelectWithOther = class SelectWithOther {
  constructor() {
    this.select = $("select.select_with_other");
    if (!this.select.length) {
      return;
    }
    this.select.change(this.onChange);
    this.init();
  }

  init() {
    this.select.each(function() {
      var input, options, select;
      select = $(this);
      input = $(
        `[data-select-with-other=${select.data("selectWithOtherName")}]`
      );
      options = select.find("option").map(function() {
        return $(this).val();
      });
      if (input.val().length > 0 && $.inArray(input.val(), options) === -1) {
        select.find('option[value="Anders nl."]').attr("selected", "selected");
        return input.prop("disabled", false);
      } else {
        return input.val("");
      }
    });
    return typeof FileUploadInitializer !== "undefined" &&
      FileUploadInitializer !== null
      ? FileUploadInitializer.adjustUploadField()
      : void 0;
  }

  onChange(e) {
    var input, select;
    select = $(e.currentTarget);
    input = $(`[data-select-with-other=${select.data("selectWithOtherName")}]`);
    if (select.val() === "Anders nl.") {
      input.prop("disabled", false).focus();
    } else {
      input.val("").prop("disabled", true);
    }
    return typeof FileUploadInitializer !== "undefined" &&
      FileUploadInitializer !== null
      ? FileUploadInitializer.adjustUploadField()
      : void 0;
  }
};

export default $(document).on("turbolinks:load", function() {
  return new SelectWithOther();
});
