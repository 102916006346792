const EditPhasePlaced = class EditPhasePlaced {
  constructor() {
    $("#client_intention")
      .change(this.onChange)
      .change();
  }

  onChange(e) {
    var selectedIntention;
    selectedIntention = $(e.currentTarget).val();
    $("#js-bsn, #js-phone, #js-intention-city").toggle(
      selectedIntention !== "" && selectedIntention !== "exit"
    );
    return $("#js-intention-date").toggle(selectedIntention !== "");
  }
};

export default $(document).on("turbolinks:load", function() {
  return new EditPhasePlaced();
});
