function mainMenu(context) {
  return {
    init: function() {
      const el = $(context.getElement());

      el.find(".dropdown__button").text(el.data("currentName"));
      el.find(`.js-selector-${el.data("currentId")}`).addClass("is-active");

      const selector = el.find(".header__selector");

      if (el.data("currentId") == "deadlines") {
        selector.addClass("header__selector--alert");
      } else if (el.data("currentId") == "my") {
        selector.addClass("header__selector--my");
      } else if (el.data("currentId") == "all") {
        selector.addClass("header__selector--all");
      } else {
        selector.addClass("header__selector--house");
      }
    }
  };
}

export default {
  name: "main-menu",
  fn: mainMenu
};
