const RegistrationForm = {
  init: function() {
    if (!$("#new_registration_form").length) {
      return;
    }
    this.submitButtonLabel();
    this.filledByOtherInput();
  },
  filledByOtherInput: function() {
    return $("#registration_form_form_filled_in_by_other")
      .change(function() {
        return $(".js-filled-by-other").toggle($(this).is(":checked"));
      })
      .change();
  },
  submitButtonLabel: function() {
    return $(".new_registration_form").submit(function() {
      return $("input[type=submit]").val("Bezig met versturen...");
    });
  }
};

export default RegistrationForm.init();
